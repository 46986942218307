import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as is from 'is';
import * as moment from 'moment';
import { AuthService } from 'app/services/auth.service';
import { DateUtil } from 'app/util/date.util';
import { UserModel } from '../models/user';
import { HttpBaseService } from './http-base.service';
import { promise } from 'selenium-webdriver';
import { UserAllRolesResponse, UserRole } from 'alyosha-libs/dist/APIModels/user.api-models';


@Injectable()
export class UserService extends HttpBaseService {
    private CONFIG: any = {};
    private pollLogsHandles: any;

    authzApiEndpoint: string;
    rolesField = 'https://decisivedata.io/roles';

    constructor(private http: HttpClient, private auth: AuthService) {
        super(http, auth);
        this.authzApiEndpoint = environment.AUTH0_AUTHS_ENDPOINT;
    }

    isCurrentUserTenantAdmin(): boolean {
        return this.auth.isCurrentUserTenantAdmin();
    }

    async getUsers(): Promise<any> {
        return new Promise((resolve, reject) => {
            this.get(`/users`).then((users) => {
                resolve(users);
            });
        });
    }

    async getUsersForTenant(tenantId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.get(`/users/usersForTenant/${tenantId}`).then((users) => {
                resolve(users);
            });
        });
    }

    async addUser(user: UserModel): Promise<any> {

        return new Promise((resolve, reject) => {
            this.post(`/users`, user).then((newUser) => {
                resolve(newUser);
            });
        });
    }

    async removeUser(user: UserModel): Promise<any> {

        return new Promise((resolve, reject) => {
            this.delete(`/users/${user.id}`).then(() => {
                resolve();
            });
        });
    }

    async syncRoles(alyoshaUserEmail: string): Promise<any> {
        return new Promise((resolve, reject) => {
            return this.get(`/users/roles/sync/${alyoshaUserEmail}`).then(() => {
                resolve();
            });
        });
    }

    async addUserToRole(email: string, roleId: string, roleName: string, add: boolean) {
        return new Promise((resolve, reject) => {
            return this.post(`/users/roles/add`, {
                roleId: roleId,
                email: email,
                add: add,
                roleName: roleName
            }).then(() => {
                resolve();
            });
        });
    }

    getAllAvailableRoles(): Promise<UserRole[]> {

        return new Promise((resolve, reject) => {

            return this.get(`/users/roles/allroles`)
                .then(((roles: UserAllRolesResponse) => {
                    return resolve(roles.roles);
                })).catch((err) => {
                    return reject(err);
                });
        });
    }

}