import { Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from './auth.guard';

import { LoginComponent } from './containers/login-logout/login.component';
import { LogoutComponent } from './containers/login-logout/logout.component';
import { CallbackComponent } from './components/callback/callback.component';
import { CallbackOAuthComponent } from './components/callbackoauth/callbackoauth.component';
import { DashboardsComponent } from './containers/dashboards/dashboards.component';
import { TenantsComponent } from './containers/tenants/tenants.component';
import { JobsComponent } from './containers/jobs/jobs.component';
import { DatasourcesComponent } from './containers/datasources/datasources.component';
import { ProfileComponent } from './containers/profile/profile.component';
import { AccountComponent } from './containers/account/account.component';
import { ExternalAuthComponent } from './components/external-auth/external-auth.component';

export const routes: Routes = [
  {
    path: '',
    component: TenantsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'callback',
    component: CallbackComponent
  },
  {
    path: 'callback-oauth',
    component: CallbackOAuthComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'tenants',
    component: TenantsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'jobs',
    component: JobsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboards',
    component: DashboardsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'datasources',
    component: DatasourcesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'externalauth',
    component: ExternalAuthComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];
/*
 {
    path: 'admin',
    component: AdminComponent,
    canActivate: [RoleGuard],
    data: {
      expectedRole: 'admin'
    }
  }
*/
