import { Injectable, Inject } from '@angular/core';
import * as is from 'is';
import * as moment from 'moment';


@Injectable()
export class Util {

    constructor(){
    }

    public FriendlyDate(str: string) {
        const d = new Date(str);
        let hours = d.getHours();
        const ampm = hours > 12 ? 'PM' : 'AM';
        hours = hours > 12 ? hours - 12 : hours;
        const toReturn = d.toLocaleDateString() + ' at ' + hours + ':' + d.getMinutes() + ampm;
        return toReturn;
    }

    public FriendlyDate2(dt: string, includeTime: boolean = true) {
        let d = new Date(dt);
        let toReturn = d.toLocaleDateString();
        if(includeTime){
            let hours = d.getHours();
            const ampm = hours > 12 ? 'PM' : 'AM';
            hours = hours > 12 ? hours - 12 : hours;
            const minutes = `${(d.getMinutes() < 10 ? '0' : '')}${d.getMinutes()}`;
            toReturn = `${d.toLocaleDateString()} - ${hours}:${minutes} ${ampm}`;
        }
        return toReturn;
    }

    public replaceAll(datString: string, replaceThis: string, withThis: string) {
        if(!datString) { return ''; }
        return datString.replace(new RegExp(this.escapeRegExp(replaceThis), 'g'), withThis);
    }

    private escapeRegExp(str) {
        return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
    }
}