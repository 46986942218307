import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import Tenant from 'app/models/tenant';
import Job from 'app/models/job';
import * as is from 'is';
import * as moment from 'moment';
import { AuthService } from 'app/services/auth.service';
import { DateUtil } from 'app/util/date.util';
import { IEventLog } from 'alyosha-libs/dist/Cloud/CloudLog';
import { UserModel } from '../models/user';
import { HttpBaseService } from './http-base.service';
import {
    TenantDataDestinationAddRequest, TenantDataDestinationDeleteRequest, TenantDataModelAddRequest, TenantDataModelRunRequest, TenantDataModelDeleteRequest,
    TenantAddRequest,
    TenantSnowflakePasswordResponse,
    TenantDataPipelineAddRequest,
    TenantDataPipelineDeleteRequest,
    TenantDataSourceAddRequest,
    TenantDataSourceDeleteRequest,
    TenantDataSourceUpdateRequest,
    TenantDataAnalyticsPlatformAddRequest,
    TenantDataAnalyticsPlatformDeleteRequest,
    TenantDataAnalyticAddRequest,
    TenantDataAnalyticDeleteRequest,
    TenantDataDestinationHourlyRunRequest,
    TenantDataPipelineHourlyRunRequest,
    TenantDataAnalyticsPlatformHourlyRunRequest,
    TenantDataSourceGenerateExternalAuthTokenRequest
} from 'alyosha-libs/dist/APIModels/tenant.api-models';

@Injectable()
export class AppService extends HttpBaseService {
    private CONFIG: any = {};
    private pollLogsHandles: any;

    constructor(private http: HttpClient, private auth: AuthService, private dateUtil: DateUtil) {
        super(http, auth);
        this.pollLogsHandles = {};
    }

    async getConfig(type: string): Promise<any> {
        if (!this.CONFIG[type]) {
            this.CONFIG[type] = await this.http.get(`${environment.API_URI}/config/${type}`, this.getHttpOptions()).toPromise();
        }
        return this.CONFIG[type];
    }

    async getTenant(id: string): Promise<Tenant> {
        return new Tenant(await this.get(`/tenants/${id}`));
    }

    async getTenants(): Promise<Array<Tenant>> {
        return (await this.get(`/tenants`)).map(t => new Tenant(t));
    }

    async addTenant(tenant: Tenant): Promise<Tenant> {
        const payload: TenantAddRequest = {
            'name': tenant.name,
            'git_url': tenant.git_url,
            'git_user': tenant.git_user,
            'git_pass': tenant.git_pass,
            'created_date': tenant.created_date,
            'created_by': tenant.created_by
        };
        return new Tenant(await this.post(`/tenants`, payload));
    }

    async getTenantSFPassword(tenantId: string): Promise<any> {
        if (!this.auth.isCurrentUserTenantAdmin()) {
            throw new Error('You are not allowed to perform this action');
        }
        return new Promise((resolve, reject) => {
            this.get(`/tenants/${tenantId}/sfpw`).then((pw: TenantSnowflakePasswordResponse) => {
                resolve(pw);
            }).catch((e) => {
                console.error(e);
            });
        });
    }

    async removeTenant(id: string): Promise<Job> {
        return new Job((await this.delete(`/tenants/${id}`))[0]);
    }

    async setTenantDestination(tenant: Tenant, destination: any): Promise<Job> {
        const payload: TenantDataDestinationAddRequest = {
            'data_destination': destination
        };
        return new Job((await this.post(`/tenants/${tenant.id}/addDataDestination`, payload))[0]);
    }

    async unsetTenantDestination(tenant: Tenant, type: string): Promise<Job> {
        const payload: TenantDataDestinationDeleteRequest = {
            'data_destination_type': type
        };
        return new Job((await this.post(`/tenants/${tenant.id}/removeDataDestination`, payload))[0]);
    }

    async setTenantModel(tenant: Tenant, dataDestinationType: string, dataModelType: string, overwrite_custom: boolean): Promise<Job> {
        const payload: TenantDataModelAddRequest = {
            'data_destination_type': dataDestinationType,
            'data_model_type': dataModelType,
            'overwrite_custom': overwrite_custom
        };
        return new Job((await this.post(`/tenants/${tenant.id}/addDataModel`, payload))[0]);
    }

    async runTenantModels(tenant: Tenant, dataDestinationType: string): Promise<Job> {
        const payload: TenantDataModelRunRequest = {
            'data_destination_type': dataDestinationType
        };
        return new Job((await this.post(`/tenants/${tenant.id}/runDataModels`, payload))[0]);
    }

    async unsetTenantModel(tenant: Tenant, dataDestinationType: string, dataModelType: string): Promise<Job> {
        const payload: TenantDataModelDeleteRequest = {
            'data_destination_type': dataDestinationType,
            'data_model_type': dataModelType
        };
        return new Job((await this.post(`/tenants/${tenant.id}/removeDataModel`, payload))[0]);

    }

    async setTenantPipeline(tenant: Tenant, pipeline: any): Promise<Job> {
        const payload: TenantDataPipelineAddRequest = {
            'data_pipeline': pipeline,
        };
        return new Job((await this.post(`/tenants/${tenant.id}/addDataPipeline`, payload))[0]);

    }

    async unsetTenantPipeline(tenant: Tenant, dataPipelineType: string): Promise<Job> {
        const payload: TenantDataPipelineDeleteRequest = {
            'data_pipeline_type': dataPipelineType
        };
        return new Job((await this.post(`/tenants/${tenant.id}/removeDataPipeline`, payload))[0]);
    }

    async setTenantSource(tenant: Tenant, dataPipelineType: string, dataSource: any): Promise<Job> {
        const payload: TenantDataSourceAddRequest = {
            'data_pipeline_type': dataPipelineType,
            'data_source': dataSource
        };
        return new Job((await this.post(`/tenants/${tenant.id}/addDataSource`, payload))[0]);
    }

    async unsetTenantSource(tenant: Tenant, dataPipelineType: string, dataSourceType: string): Promise<Job> {
        const payload: TenantDataSourceDeleteRequest = {
            'data_pipeline_type': dataPipelineType,
            'data_source_type': dataSourceType
        };
        return new Job((await this.post(`/tenants/${tenant.id}/removeDataSource`, payload))[0]);
    }

    async updateTenantSource(tenant: Tenant, dataPipelineType: string, dataSource: any): Promise<Job> {
        const payload: TenantDataSourceUpdateRequest = {
            'data_pipeline_type': dataPipelineType,
            'data_source': dataSource
        };
        return new Job((await this.post(`/tenants/${tenant.id}/updateDataSource`, payload))[0]);
    }
    
    async generateExternalAuthToken(tenantId: string, dataPipelineType: string, dataSourceType: string): Promise<Job> {
        const payload: TenantDataSourceGenerateExternalAuthTokenRequest = {
            'data_pipeline_type': dataPipelineType,
            'data_source_type': dataSourceType
        };
        return new Job((await this.post(`/tenants/${tenantId}/generateExternalAuthToken`, payload))[0]);
    }

    async setTenantAnalyticsPlatform(tenant: Tenant, dataAnalyticsPlatform: any): Promise<Job> {
        const payload: TenantDataAnalyticsPlatformAddRequest = {
            'data_analytics_platform': dataAnalyticsPlatform
        };
        return new Job((await this.post(`/tenants/${tenant.id}/addDataAnalyticsPlatform`, payload))[0]);

    }

    async unsetTenantAnalyticsPlatform(tenant: Tenant, dataAnalyticsPlatformType: string): Promise<Job> {
        const payload: TenantDataAnalyticsPlatformDeleteRequest = {
            'data_analytics_platform_type': dataAnalyticsPlatformType
        };
        return new Job((await this.post(`/tenants/${tenant.id}/removeDataAnalyticsPlatform`, payload))[0]);
    }

    async setTenantAnalytic(tenant: Tenant, dataDestinationType: string, dataSourceType: string, dataAnalyticsPlatformType: string, dataAnalyticsType: string): Promise<Job> {
        const payload: TenantDataAnalyticAddRequest = {
            'data_destination_type': dataDestinationType,
            'data_source_type': dataSourceType,
            'data_analytics_platform_type': dataAnalyticsPlatformType,
            'data_analytics_type': dataAnalyticsType
        };
        return new Job((await this.post(`/tenants/${tenant.id}/addDataAnalytic`, payload))[0]);
    }

    async unsetTenantAnalytic(tenant: Tenant, dataDestinationType: string, dataSourceType: string, dataAnalyticsPlatformType: string, dataAnalyticsType: string): Promise<Job> {
        const payload: TenantDataAnalyticDeleteRequest = {
            'data_destination_type': dataDestinationType,
            'data_source_type': dataSourceType,
            'data_analytics_platform_type': dataAnalyticsPlatformType,
            'data_analytics_type': dataAnalyticsType
        };
        return new Job((await this.post(`/tenants/${tenant.id}/removeDataAnalytic`, payload))[0]);
    }

    async runHourlyTasks(tenant: Tenant, type: string, subtype: string, ds?: string): Promise<any> {
        switch (type) {
            case 'dest':
                return await this.runTenantDataDestinationHourlyTasks(tenant, subtype, ds);
            case 'pipe':
                return await this.runTenantDataPipelineHourlyTasks(tenant, subtype, ds);
            case 'plat':
                return await this.runTenantDataAnalyticsPlatformHourlyTasks(tenant, subtype, ds);
        }
        return await Promise.resolve();
    }

    async runTenantDataDestinationHourlyTasks(tenant: Tenant, dataDestinationType: string, dataSourceType: string): Promise<Job> {
        const payload: TenantDataDestinationHourlyRunRequest = {
            'data_destination_type': dataDestinationType,
            'data_source_type': dataSourceType
        };
        return new Job((await this.post(`/tenants/${tenant.id}/runDataDestinationHourlyTasks`, payload))[0]);
    }

    async runTenantDataPipelineHourlyTasks(tenant: Tenant, dataPipelineType: string, dataSourceType: string): Promise<Job> {
        const payload: TenantDataPipelineHourlyRunRequest = {
            'data_pipeline_type': dataPipelineType,
            'data_source_type': dataSourceType
        };
        return new Job((await this.post(`/tenants/${tenant.id}/runDataPipelineHourlyTasks`, payload))[0]);
    }

    async runTenantDataAnalyticsPlatformHourlyTasks(tenant: Tenant, dataAnalyticsPlatformType: string, dataSourceType: string): Promise<Job> {
        const payload: TenantDataAnalyticsPlatformHourlyRunRequest = {
            'data_analytics_platform_type': dataAnalyticsPlatformType,
            'data_source_type': dataSourceType
        };
        return new Job((await this.post(`/tenants/${tenant.id}/runDataAnalyticsPlatformHourlyTasks`, payload))[0]);
    }

    getJob(id: string): Promise<Job> {
        return this.http.get(`${environment.API_URI}/jobs/${id}?steps=true&detail=true`, this.getHttpOptions()).toPromise()
            .then(d => new Job(d));
    }

    getJobs(ids: Array<String>): Promise<Array<Job>> {
        return this.http.get(`${environment.API_URI}/jobs?id=${ids.join(',')}`, this.getHttpOptions()).toPromise()
            .then(d => (d as Array<any>).map(j => new Job(j)));
    }

    awaitJob(id: string, progress?: (job: Job) => void): Promise<Job> {
        return new Promise((resolve, reject) => {
            const find_running_steps = (steps): Array<any> => {
                let running = [];
                for (const step of steps) {
                    if (step.state_current === 'running') {
                        running.push(step);
                    }
                    if (step.children) {
                        const children = [];
                        for (const child in step.children) {
                            if (step.children[child]) {
                                children.push(step.children[child]);
                            }
                        }
                        running = running.concat(find_running_steps(children));
                    }
                }
                return running;
            };
            const start_time = moment();
            const interval_time = 3000;
            const interval_method = () => {
                this.getJob(id).then((data: any) => {
                    if (progress) {
                        progress(new Job(data));
                    }
                    const lockeduntil = moment.utc(data.lockeduntil).local();
                    const polling = find_running_steps(data.steps).filter(s => s.poll).length > 0;
                    //if im successful...
                    if (typeof data.success === 'boolean') {
                        if (data.success) {
                            resolve(new Job(data));
                        }
                        else {
                            //give progress a chance to do its thing
                            setTimeout(() => { reject(); }, 100);
                        }
                    }
                    else if (lockeduntil.isSameOrBefore(moment())) {
                        resolve(new Job(data));
                        alert('job timed out');
                    }
                    //if im running a polled step, just return to the client!
                    else if (polling) {
                        resolve(new Job(data));
                        //but keep checking
                        setTimeout(interval_method, interval_time)
                    }
                    else {
                        //keep checking
                        setTimeout(interval_method, interval_time);
                    }

                }
                );
            };
            //call out of the gates
            interval_method();
        });
    }

    startPollLogs(tenant: Tenant, callback?: (logs: IEventLog[]) => void): Promise<void> {
        const pollOnlyOnce = false;
        // check if we already are polling for logs for tenant
        if ((this.pollLogsHandles[tenant.id] || {}).promise) {
            return this.pollLogsHandles[tenant.id].promise;
        }

        this.pollLogsHandles[tenant.id] = this.pollLogsHandles[tenant.id] || {};
        this.pollLogsHandles[tenant.id].initialized = false;

        const promise = new Promise<void>((resolve, reject) => {
            if (!tenant) {
                reject();
            }

            const interval_time = 3000;
            const interval_method = async () => {
                // check if the poll has been initialized yet
                if (!(this.pollLogsHandles[tenant.id] || {}).initialized) {
                    setTimeout(interval_method);
                    return;
                }

                // if we have been told to stop polling, then stop
                if (this.pollLogsHandles[tenant.id].promise !== promise) {
                    resolve();
                    return;
                }

                // get from api
                let logs = await this.getTenantLogs(tenant, this.pollLogsHandles[tenant.id].lastPollTime);

                //notify callback
                if ((logs || []).length) {
                    logs = logs.sort((a, b) => {
                        return a.timestamp < b.timestamp ? -1 : a.timestamp > b.timestamp ? 1 : 0;
                    });

                    // keep track of when we last polled
                    this.pollLogsHandles[tenant.id].lastPollTime = new Date(logs[logs.length - 1].timestamp + 1);
                }

                callback(logs);

                if (pollOnlyOnce) {
                    this.stopPollLogs(tenant);
                }
                else {
                    // continue polling
                    setTimeout(interval_method, interval_time);
                }
            }

            interval_method();
        });

        this.pollLogsHandles[tenant.id].promise = promise;
        this.pollLogsHandles[tenant.id].initialized = true;

        return promise;
    }

    stopPollLogs(tenant: Tenant) {
        // poll doesnt exist for tenant
        if (!tenant || !this.pollLogsHandles[tenant.id]) {
            return;
        }

        // let the poll know to stop next time it runs
        delete this.pollLogsHandles[tenant.id].promise;
    }


    async getTenantLogs(tenant: Tenant, startTime?: Date, endTime?: Date): Promise<Array<IEventLog>> {
        const startTimeStr = this.dateUtil.isValid(startTime) ? startTime.getTime() : '';
        const endTimeStr = this.dateUtil.isValid(endTime) ? endTime.getTime() : moment().utc().toDate().getTime();
        let params = '';
        if (startTimeStr) {
            params += `start_time=${startTimeStr}&`;
        }
        if (startTimeStr) {
            params += `end_time=${endTimeStr}`;
        }
        const url = `${environment.API_URI}/tenants/${tenant.id}/logs?${params}`;
        const response = await this.http.get(url, this.getHttpOptions()).toPromise();
        return response as IEventLog[];
    }


}