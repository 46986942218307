import { Component, OnInit } from '@angular/core';
import { AuthService } from './services/auth.service';

import { HeaderComponent } from './containers/header/header.component';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(public auth: AuthService, private router: Router, private location: Location) { 

  }

  ngOnInit(){
    this.auth.checkSession(); // get a new token if the token will expire soon, improve user experience
  } 

  isCallbackRoute(): boolean {
    const path = this.location.path();
    const route = path.substring(0, path.indexOf('?') || path.length);
    return route.toLowerCase().indexOf('callback') > -1 &&
      //route.toLowerCase().indexOf('oauth') > -1 &&
      true;
  }
}
