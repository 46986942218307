import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { routes } from './app.route';

import { AuthService } from './services/auth.service';
import { AuthGuardService } from './auth.guard';
import { AppService } from 'app/services/app.service';
import { UserService } from 'app/services/user.service';
import { Util } from './services/util.service';

import { AppComponent } from './app.component';
import { CallbackComponent } from './components/callback/callback.component';
import { CallbackOAuthComponent } from './components/callbackoauth/callbackoauth.component';
import { HeaderComponent } from './containers/header/header.component';
import { LoginComponent } from './containers/login-logout/login.component';
import { LogoutComponent } from './containers/login-logout/logout.component';
import { TenantsComponent } from './containers/tenants/tenants.component';
import { JobsComponent } from './containers/jobs/jobs.component';
import { DashboardsComponent } from './containers/dashboards/dashboards.component';
import { DatasourcesComponent } from './containers/datasources/datasources.component';
import { ProfileComponent } from './containers/profile/profile.component';
import { AccountComponent } from './containers/account/account.component';
import { TenantComponent } from './components/tenant/tenant.component';
import { JobtreeComponent } from 'app/components/jobtree/jobtree.component';
import { UsersComponent } from './components/tenant/users/users.component';
import { LogListComponent } from './components/log-list/log-list.component';
import { LogComponent } from './components/log-list/log/log.component';
import { StitchSourceComponent } from './components/tenant/stitch_source/stitch_source.component';


// primeng
import {ButtonModule} from 'primeng/button';
import {KeyFilterModule} from 'primeng/keyfilter';
import {TableModule} from 'primeng/table';
import {TabMenuModule} from 'primeng/tabmenu';
import {TabViewModule} from 'primeng/tabview';
import {MenubarModule} from 'primeng/menubar';
import {DataTableModule} from 'primeng/datatable';
import {SharedModule} from 'primeng/shared';
import {AccordionModule} from 'primeng/accordion';
import {FieldsetModule} from 'primeng/fieldset';
import {InputTextModule} from 'primeng/inputtext';
import {CheckboxModule} from 'primeng/checkbox';
import {DialogModule} from 'primeng/dialog';
import {ConfirmationService} from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {BlockUIModule} from 'primeng/blockui';
import {CodeHighlighterModule} from 'primeng/codehighlighter';
import {PasswordModule} from 'primeng/password';
import {SidebarModule} from 'primeng/sidebar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {ProgressBarModule} from 'primeng/progressbar';
import {SpinnerModule} from 'primeng/spinner';
import {ChipsModule} from 'primeng/chips';

import { DateUtil } from 'app/util/date.util';
import { SafePipe } from './pipes/safe.pipe';
import { ExternalAuthComponent } from './components/external-auth/external-auth.component';
import { ExternalAuthService } from './services/external-auth.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    LogoutComponent,
    CallbackComponent,
    CallbackOAuthComponent,
    TenantsComponent,
    JobsComponent,
    DashboardsComponent,
    DatasourcesComponent,
    ProfileComponent,
    AccountComponent,
    TenantComponent,
    JobtreeComponent,
    LogListComponent,
    LogComponent,
    UsersComponent,
    StitchSourceComponent,
    SafePipe,
    ExternalAuthComponent
    ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    // PrimeNG Module Imports
    ButtonModule,
    TabMenuModule,
    TabViewModule,
    AccordionModule,
    MenubarModule,
    DataTableModule,
    FieldsetModule,
    CheckboxModule,
    InputTextModule,
    PasswordModule,
    DialogModule,
    ConfirmDialogModule,
    BlockUIModule,
    CodeHighlighterModule,
    SidebarModule,
    TooltipModule,
    TreeModule,
    ProgressBarModule,
    SpinnerModule,
    ChipsModule,
    KeyFilterModule,
    TableModule
  ],
  providers: [AuthService, AuthGuardService, AppService, UserService, ConfirmationService, DateUtil, Util, ExternalAuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
