import { IEnvironment } from './environment.interface';
import { commonEnvironment } from './environment.common';

export function buildEnvironment(specificEnvironment: Partial<IEnvironment>): IEnvironment {
    const environment = Object.assign(<IEnvironment>{
        get API_URI() {
            const _this: IEnvironment = this;
            const version = (_this.API_VERSION && _this.API_VERSION.length) ? '/' + _this.API_VERSION : '';
            return _this.BASE_API_URI + version;
        }
    }, commonEnvironment, specificEnvironment);
    return environment;
}