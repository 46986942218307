import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-callback',
    templateUrl: './callback.component.html',
    styleUrls: ['./callback.component.scss']
})
export class CallbackComponent {

    emailField: string = 'https://decisivedata.io/email';

    constructor(public auth: AuthService, private router: Router, private userService: UserService) {
        this.auth.handleAuthentication(() => {
            //get the users profile before proceeding!
            if (this.auth.userProfile) {
                //sync roless
                this.userService.syncRoles(this.auth.userProfile[this.emailField]).then(()=>{
                    this.router.navigate(['']);
                });

            } else {
                this.auth.getProfile(() => {
                    //sync roles
                    this.userService.syncRoles(this.auth.userProfile[this.emailField]).then(()=>{
                        this.router.navigate(['']);
                    });
                });
            }
        });
    }


}