import { Component, Input, ChangeDetectorRef, ChangeDetectionStrategy, EventEmitter, Output, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { AppService } from 'app/services/app.service';
import Tenant from '../../../models/tenant';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-stitch-source',
    templateUrl: './stitch_source.component.html',
    styleUrls: ['./stitch_source.component.scss'] 
})
export class StitchSourceComponent {

    @Input() tenant: Tenant;
    @Input() source_type: string;

    private Stitch: any;

    constructor(private cdr: ChangeDetectorRef, private appService: AppService) {
        this.Stitch = window['Stitch'];
    }

    start() {
        const source = this.tenant.getDataSourcePath('stitch', this.source_type);
        const streams = {};
        //compile objects into streams
        for (const obj of source.objects || []) {
            streams[obj.name] = true;
        }
        //now auth the source!
        this.Stitch.authorizeSource({ 
            'id': source.id,
            'default_streams': streams
        }).then((result) => {
            //nothing to be done!
        }).catch((error) => {
            //alert('There was a problem finishing the Source')
            console.log('Integration not created.', error);
        });
    }
}