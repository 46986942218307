import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

export abstract class HttpBaseService {

    constructor(private _http: HttpClient,
        private _auth: AuthService
    ) {

    }

    protected async get(urlPart: string, overrideApi = false, inHeaders: any = null): Promise<any> {
        const url = overrideApi === true ? urlPart : `${environment.API_URI}${urlPart}`;
        if (!inHeaders) { inHeaders = this.getHttpOptions(); }

        return await this._http.get(url, inHeaders)
            .toPromise();
    }

    protected async post(urlPart: string, body: any, overrideApi = false, inHeaders: any = null): Promise<any> {
        const url = overrideApi === true ? urlPart : `${environment.API_URI}${urlPart}`;
        if (!inHeaders) { inHeaders = this.getHttpOptions(); }

        return await this._http.post(url, body, inHeaders)
            .toPromise();
    }

    protected async put(urlPart: string, body: any, overrideApi = false, inHeaders: any = null): Promise<any> {
        const url = overrideApi === true ? urlPart : `${environment.API_URI}${urlPart}`;
        if (!inHeaders) { inHeaders = this.getHttpOptions(); }

        return await this._http.put(url, body, inHeaders)
            .toPromise();
    }

    protected async delete(urlPart: string, overrideApi = false, inHeaders: any = null): Promise<any> {
        const url = overrideApi === true ? urlPart : `${environment.API_URI}${urlPart}`;
        if (!inHeaders) { inHeaders = this.getHttpOptions(); }

        return await this._http.delete(url, inHeaders)
            .toPromise();
    }

    protected async patch(urlPart: string, body: any, overrideApi = false, inHeaders: any = null): Promise<any> {
        const url = overrideApi === true ? urlPart : `${environment.API_URI}${urlPart}`;
        if (!inHeaders) { inHeaders = this.getHttpOptions(); }

        return await this._http.patch(url, body, inHeaders)
            .toPromise();
    }


    protected getHttpOptions() {
        const authInfo = this._auth.getSession();
        return {
            'headers': {
                'Authorization': `Bearer ${authInfo.access_token}`
            }
        }
    }

}