import { Component, Input, ChangeDetectorRef, ChangeDetectionStrategy, EventEmitter, Output, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppService } from 'app/services/app.service';
import { ConfirmationService, TreeNode, TabView } from 'primeng/primeng';
import Tenant from 'app/models/tenant';
import * as is from 'is';
import * as extend from 'extend';
import Job from 'app/models/job';
import * as moment from 'moment';
import { IEventLog } from 'alyosha-libs/dist/Cloud/CloudLog';
import { Log } from '../log-list/log/log.model';
import { Util } from '../../services/util.service';
import { AuthService } from '../../services/auth.service';
import * as uuidv4 from 'uuid/v4'

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-tenant',
    templateUrl: './tenant.component.html',
    styleUrls: ['./tenant.component.scss']
})
export class TenantComponent implements OnInit, OnDestroy {
    @ViewChild('jobSidebar') jobSidebar;
    @ViewChild('tabsTenant') tabsTenant;
    @Input() tenant: Tenant;
    @Output() jobStarted = new EventEmitter();


    errorMessage: string | null = null;
    blockedTabs = true;
    job: Job;
    jobs: Array<Job> = [];
    showJob = false;
    blockedJob = false;
    tenantJSON = '';

    logs: IEventLog[] = [];

    datadestinations: Array<any> = [];
    datapipelines: Array<any> = [];
    dataanalyticsplatforms: Array<any> = [];
    datasources: any = {
        'all': []
    };
    datamodels: Array<any> = [];
    dataanalytics: any = {} = [];

    datadestination_selected: any;
    datapipeline_selected: any;
    dataanalyticsplatform_selected: any;
    datasource_selected: any;

    addTenantPopup = false;
    new_tenant_name: string;
    new_dest: any = {};
    new_dest_hosted = {};
    new_pipe: any = {};
    new_pipe_hosted = {};
    new_plat: any = {};
    new_plat_hosted = {};
    new_source: any = {};


    constructor(private cdr: ChangeDetectorRef, private appService: AppService, private confirmationService: ConfirmationService, public util: Util, private auth: AuthService) {

    }

    isTenantAdmin() {
        return this.auth.isCurrentUserTenantAdmin();
    }

    retrievePassword(e) {
        if (!this.isTenantAdmin()) {
            return;
        } else {
            this.blockedTabs = true;
            this.cdr.detectChanges();
            this.appService.getTenantSFPassword(this.tenant.id).then((pw) => {
                if (pw && pw.pw) {
                    this.confirmationService.confirm({
                        key: 'tenant_pw_confirm',
                        message: pw.pw,
                        accept: () => {
                            return;
                        }
                    });
                }
                this.blockedTabs = false;
                this.cdr.detectChanges();
            });
        }

    }

    async ngOnInit() {
        await this.bindTenantToUI();
        this.blockedTabs = false;
        this.cdr.detectChanges();
    }

    ngOnDestroy(): void {
        this.stopEventLogsPoll();
    }

    errorMessagePopupClose() {
        this.errorMessage = null;
        this.cdr.detectChanges();
    }

    jobPopupShow() {
        this.showJob = true;
        this.cdr.detectChanges();
    }

    async addDestination(type: string) {
        //check fields
        if (!this.new_dest_hosted[type]) {
            for (const field of this.datadestinations.filter(d => d.type === type)[0].fields) {
                //apply default
                if (is.defined(field.default) && is.empty(this.new_dest[type][field.field])) {
                    this.new_dest[type][field.field] = field.default;
                }
                //check value
                if (field.required && is.empty(this.new_dest[type][field.field]) && this.new_dest[type][field.field] !== false) {
                    this.errorMessage = `Missing required field '${field.name}'`;
                    this.cdr.detectChanges();
                    return;
                }
            }
        }
        //create the job!
        await this.runJob(() => {
            return this.appService.setTenantDestination(this.tenant, {
                'type': type,
                'connection': is.empty(this.new_dest[type]) ? undefined : this.new_dest[type]
            });
        });
    }

    async removeDestination(type: string) {
        await this.runJob(() => {
            return this.appService.unsetTenantDestination(this.tenant, type);
        });
    }

    isDestinationOAuth(type: string) {
        for (const field of this.datadestinations.filter(d => d.type === type)[0].fields) {
            if (field.type === 'oauth') {
                return true;
            }
        }
        return false;
    }

    async addPipeline(type: string) {
        //check fields
        if (!this.new_pipe_hosted[type]) {
            for (const field of this.datapipelines.filter(d => d.type === type)[0].fields) {
                //apply default
                if (is.defined(field.default) && is.empty(this.new_pipe[type][field.field])) {
                    this.new_pipe[type][field.field] = field.default;
                }
                //check value
                if (field.required && is.empty(this.new_pipe[type][field.field]) && this.new_pipe[type][field.field] !== false) {
                    this.errorMessage = `Missing required field '${field.name}'`;
                    this.cdr.detectChanges();
                    return;
                }
            }
        }
        //create the job!
        await this.runJob(() => {
            return this.appService.setTenantPipeline(this.tenant, {
                'type': type,
                'connection': is.empty(this.new_pipe[type]) ? undefined : this.new_pipe[type]
            });
        });
    }

    async removePipeline(type: string) {
        await this.runJob(() => {
            return this.appService.unsetTenantPipeline(this.tenant, type);
        });
    }

    isPipelineOAuth(type: string) {
        for (const field of this.datapipelines.filter(d => d.type === type)[0].fields) {
            if (field.type === 'oauth') {
                return true;
            }
        }
        return false;
    }

    setDataSourceField(pipe, source, field, value) {
        if (!this.new_source[pipe]) {
            this.new_source[pipe] = {};
        }
        if (!this.new_source[pipe][source]) {
            this.new_source[pipe][source] = {};
        }
        this.new_source[pipe][source][field] = value;
        this.cdr.detectChanges();
    }

    async addSource(pipe: string, type: string) {
        const ds = this.datasources[pipe].filter(d => d.type === type)[0];
        //check fields
        if (!ds.pipes[pipe].ignore_all_fields && !ds.pipes[pipe].ignore_all_fields_but_objects) {
            for (const field of ds.fields) {
                //apply default
                if (is.defined(field.default) && is.empty(this.new_source[pipe][type][field.field])) {
                    this.new_source[pipe][type][field.field] = field.default;
                }
                //check value
                if (field.required && is.empty(this.new_source[pipe][type][field.field]) && this.new_source[pipe][type][field.field] !== false) {
                    this.errorMessage = `Missing required field '${field.name}'`;
                    return;
                }
            }
            this.cdr.detectChanges();
        }
        //set type
        this.new_source[pipe][type].type = type;
        //create the job!
        await this.runJob(() => {
            const source = extend(true, {},
                this.new_source[pipe][type],
                {
                    'objects': this.new_source[pipe][type].objects.map(o => {
                        return {
                            'name': o.name,
                            'columns': o.keys ? o.keys.split(',').map(k => {
                                return {
                                    'name': k,
                                    'key': true
                                }
                            }) : []
                        }
                    })
                }
            );
            if (this.new_source[pipe][type].authorization) {
                source.authorization = this.new_source[pipe][type].authorization;
            }
            //remove keys from objects
            for (const obj of source.objects) {
                obj.keys = undefined;
                delete obj.keys;
            }
            //and run the job!
            return this.appService.setTenantSource(this.tenant, pipe, source);
        });
    }

    async removeSource(pipe: string, type: string) {
        await this.runJob(() => {
            return this.appService.unsetTenantSource(this.tenant, pipe, type);
        });
    }

    async updateSource(pipe: string, type: string) {
        try {
            //check fields
            for (const field of this.datasources[pipe].filter(d => d.type === type)[0].fields) {
                //apply default
                if (is.defined(field.default) && is.empty(this.new_source[pipe][type][field.field])) {
                    this.new_source[pipe][type][field.field] = field.default;
                }
                //check value
                if (field.required && is.empty(this.new_source[pipe][type][field.field]) && this.new_source[pipe][type][field.field] !== false) {
                    this.errorMessage = `Missing required field '${field.name}'`;
                    return;
                }
            }
            this.cdr.detectChanges();

            // remove existing encrypted password, otherwise it will not know to encrypt the new password
            delete this.new_source[pipe][type]['*password'];

            //set type
            this.new_source[pipe][type].type = type;
            //create the job!
            await this.runJob(() => {
                const source = extend(true, {},
                    this.new_source[pipe][type],
                    {
                        'objects': this.new_source[pipe][type].objects.map(o => {
                            return {
                                'name': o.name,
                                'columns': o.keys ? o.keys.split(',').map(k => {
                                    return {
                                        'name': k,
                                        'key': true
                                    }
                                }) : []
                            }
                        })
                    }
                );

                if (this.new_source[pipe][type].authorization) {
                    source.authorization = this.new_source[pipe][type].authorization;
                }

                //remove keys from objects
                for (const obj of source.objects) {
                    obj.keys = undefined;
                    delete obj.keys;
                }
                //and run the job!
                return this.appService.updateTenantSource(this.tenant, pipe, source);
            });
        }
        catch (err) {
            console.log(err);
        }
    }

    addDataSourceObject(pipe, source) {
        if (!this.new_source[pipe][source].objects) {
            this.new_source[pipe][source].objects = [];
        }
        this.new_source[pipe][source].objects.push({ 'name': '', 'columns': [] });
        this.cdr.detectChanges();
    }

    removeDataSourceObject(pipe, source, object) {
        this.new_source[pipe][source].objects = this.new_source[pipe][source].objects.filter(o => o !== object);
        this.cdr.detectChanges();
    }

    async pivotModel(pivot: boolean, dest: string, source: string) {
        if (pivot) {
            await this.addModel(dest, source);
        }
        else {
            await this.removeModel(dest, source);
        }
    }

    async runModels(type: string) {
        await this.runJob(() => {
            return this.appService.runTenantModels(this.tenant, type);
        });
    }

    async addModel(dest: string, source: string) {
        this.confirmationService.confirm({
            key: 'tenant_overwrite_confirm',
            message: 'Would you like to overwrite any existing \'custom\' scripts?',
            accept: () => {
                this.runJob(() => {
                    return this.appService.setTenantModel(this.tenant, dest, source, true);
                });
            },
            reject: () => {
                this.runJob(() => {
                    return this.appService.setTenantModel(this.tenant, dest, source, false);
                });
            }
        });

    }

    async removeModel(dest: string, source: string) {
        this.blockedTabs = true;
        await this.runJob(() => {
            return this.appService.unsetTenantModel(this.tenant, dest, source);
        });
    }

    async addAnalyticsPlatform(type: string) {
        //check fields
        if (!this.new_plat_hosted[type]) {
            for (const field of this.dataanalyticsplatforms.filter(d => d.type === type)[0].fields) {
                if (field.required && is.empty(this.new_plat[type][field.field])) {
                    this.errorMessage = `Missing required field '${field.name}'`;
                    return;
                }
            }
        }
        //create the job!
        await this.runJob(() => {
            return this.appService.setTenantAnalyticsPlatform(this.tenant, {
                'type': type,
                'connection': is.empty(this.new_plat[type]) ? undefined : this.new_plat[type]
            });
        });
    }

    async removeAnalyticsPlatform(type: string) {
        await this.runJob(() => {
            return this.appService.unsetTenantAnalyticsPlatform(this.tenant, type);
        });
    }

    isAnalyticsPlatformOAuth(type: string) {
        for (const field of this.dataanalyticsplatforms.filter(d => d.type === type)[0].fields) {
            if (field.type === 'oauth') {
                return true;
            }
        }
        return false;
    }

    async pivotAnalytic(pivot: boolean, dest: string, source: string, plat: string, analy: string) {
        if (pivot) {
            await this.addAnalytic(dest, source, plat, analy);
        }
        else {
            await this.removeAnalytic(dest, source, plat, analy);
        }
    }

    async addAnalytic(dest: string, source: string, plat: string, analy: string) {
        await this.runJob(() => {
            return this.appService.setTenantAnalytic(this.tenant, dest, source, plat, analy);
        });
    }

    async removeAnalytic(dest: string, source: string, plat: string, analy: string) {
        await this.runJob(() => {
            return this.appService.unsetTenantAnalytic(this.tenant, dest, source, plat, analy);
        });
    }

    async runHourlyTasks(type: string, subtype: string, ds?: string) {
        await this.runJob(() => {
            return this.appService.runHourlyTasks(this.tenant, type, subtype, ds);
        });
    }

    async runOAuthProcess(oauth_url: string, type: string, subtype: string, fieldName: string) {
        //show loading
        this.blockedTabs = true;
        this.cdr.detectChanges();
        //create global callback
        window[`__callbackOauth_${this.tenant.id}`] = ((params: any) => {
            window[`__callbackOauth_${this.tenant.id}`] = undefined;
            delete window[`__callbackOauth_${this.tenant.id}`];
            //show status of the newly created job
            if (params) {
                switch (type) {
                    case 'dest':
                        extend(this.new_dest[subtype], params.connection);
                        this.addDestination(subtype);
                        break;
                    case 'pipe':
                        extend(this.new_pipe[subtype], params.connection);
                        this.addPipeline(subtype);
                        break;
                    case 'plat':
                        extend(this.new_plat[subtype], params.connection);
                        this.addAnalyticsPlatform(subtype);
                        break;

                }
            }
            else {
                //user canceled auth, so just hide loading
                this.blockedTabs = false;
                this.showJob = false;
                this.cdr.detectChanges();
            }
        });
        //open the oauth window and wait for auth to complete...
        await (new Promise((resolve, reject) => {
            const oauthWin = window.open(
                oauth_url.replace(/\{redirect\}/g, `${location.protocol}%2F%2F${location.host}%2Fcallback-oauth`)
                    .replace(/\{nonce\}/g, `${this.tenant.id}-${(new Date()).getTime()}`)
                    .replace(/\{state\}/g, `${this.tenant.id}|${type}|${subtype}|${fieldName}`)
                    .replace(/\{tenant_id\}/g, this.tenant.id),
                'blank',
                'width=500,height=600,top=100,left=500'
            );
            //poll to detect closure
            const closeCheck = setInterval(async () => {
                if (oauthWin.closed !== false) {
                    clearInterval(closeCheck);
                    //merge the new tenant data in!
                    if (window[`__callbackOauth_${this.tenant.id}`]) {
                        //window closed without resolution
                        window[`__callbackOauth_${this.tenant.id}`] = undefined;
                        delete window[`__callbackOauth_${this.tenant.id}`];
                        //hide loading
                        this.blockedTabs = false;
                        this.showJob = false;
                        this.cdr.detectChanges();
                        //and finish
                        resolve();
                    }
                }
            }, 300);
        }));
    }

    async getAndShowJob(id, callback?: () => void) {
        this.showJob = true;
        this.job = null;
        this.cdr.detectChanges();
        const job = await this.appService.getJob(id);
        this.job = job;
        if (!this.jobs.filter(j => j.id === job.id).length) {
            this.jobs.push(job);
        }
        this.cdr.detectChanges();
        await this.appService.awaitJob(id, (_job: Job) => {
            job.merge(_job);
            this.cdr.detectChanges();
        });
        //callback
        if (callback) {
            setTimeout(callback, 10);
        }
    };

    async runJob(callback: () => Promise<Job>) {
        let job: Job;
        try {
            this.blockedTabs = true;
            //this.blockedJob = true;
            this.showJob = true;
            this.job = null;
            this.cdr.detectChanges();
            //get the job
            job = await callback();
            this.job = job;
            if (!this.jobs.filter(j => j.id === job.id).length) {
                this.jobs.push(job);
            }
            this.blockedJob = false;
            this.cdr.detectChanges();
            //now wait for the job to finish
            await this.appService.awaitJob(job.id, (_job: Job) => {
                job.merge(_job);
                this.cdr.detectChanges();
            });
            //merge the new tenant data in!
            const _tenant = await this.appService.getTenant(this.tenant.id);
            this.tenant.merge(_tenant);
            await this.bindTenantToUI();
            //hide progress pane
            this.showJob = false;
            this.cdr.detectChanges();
        }
        catch (ex) {
            //job failed, undo actions
            if (job && job.status && job.status.failed) {
                if (!this.showJob) {
                    this.showJob = true;
                }
                this.errorMessage = `Job Failed...`;
            }
            else if (is.array(ex) && ex.length) {
                this.errorMessage = `Error...<br/><br/>${ex.map(e => JSON.stringify(e)).join('<br/><br/>')}`;
            }
            else if (ex && ex !== null) {
                this.errorMessage = JSON.stringify(ex);
            }
            else {
                this.errorMessage = 'ERROR!!!';
            }
            this.blockedTabs = false;
        }
        this.blockedTabs = false;
        this.cdr.detectChanges();
    }

    isEventLogsSelected(selectedIndex: number, tabsTenant: TabView) {
        return tabsTenant.tabPanels.find((item, index) => index === selectedIndex).header === 'Logs';
    }

    tabViewChangeHandler(event: any, tabsTenant: TabView) {
        if (this.isEventLogsSelected(event.index, tabsTenant)) {
            this.startEventLogsPoll();
        }
        else {
            this.stopEventLogsPoll();
        }
    }

    startEventLogsPoll() {
        this.appService.startPollLogs(this.tenant, (logs) => {
            // when new events are found, display them

            this.logs = [...this.logs, ...<any>logs];
            this.cdr.detectChanges();
        });
    }

    stopEventLogsPoll() {
        this.appService.stopPollLogs(this.tenant);
    }

    async bindTenantToUI() {

        this.datadestinations = [];
        this.datapipelines = [];
        this.dataanalyticsplatforms = [];
        this.datasources = {
            'all': []
        };
        this.datamodels = [];
        this.dataanalytics = {} = [];

        const config_datadestinations = await this.appService.getConfig('datadestinations');
        const config_datasources = await this.appService.getConfig('datasources');
        const config_datamodels = await this.appService.getConfig('datamodels');
        const config_datapipelines = await this.appService.getConfig('datapipelines');
        const config_dataanalyticsplatforms = await this.appService.getConfig('dataanalyticsplatforms');
        const config_dataanalytics = await this.appService.getConfig('dataanalytics');
        for (const source in config_datasources) {
            if (config_datasources[source]) {
                //add sources
                const _source: any = {
                    'type': source,
                    'name': config_datasources[source].name,
                    'fields': [],
                    'pipes': config_datasources[source].datapipelines
                };
                //add fields
                if (is.object(config_datasources[source].fields)) {
                    for (const field in config_datasources[source].fields) {
                        if (config_datasources[source].fields[field]) {
                            _source.fields.push(extend({ 'field': field, 'name': field }, config_datasources[source].fields[field]));
                        }
                    }
                }
                //add pipelines
                for (const pipe in config_datasources[source].datapipelines) {
                    if (config_datasources[source].datapipelines) {
                        const __source = extend(true, {}, _source);
                        if (!this.datasources[pipe]) {
                            this.datasources[pipe] = [];
                        }
                        this.datasources[pipe].push(__source);
                        //prepend pipe fields fields
                        if (is.object(config_datasources[source].datapipelines[pipe].fields)) {
                            let x = 0;
                            for (const field in config_datasources[source].datapipelines[pipe].fields) {
                                if (config_datasources[source].datapipelines[pipe].fields[field]) {
                                    __source.fields.splice(x, 0, extend({ 'field': field, 'name': field }, config_datasources[source].datapipelines[pipe].fields[field]));
                                    x++;
                                }
                            }
                        }
                    }
                }

                //add to all
                this.datasources.all.push(_source);
            }
        }
        for (const model in config_datamodels) {
            if (config_datamodels[model]) {
                this.datamodels.push(extend(config_datamodels[model], { 'type': model }));
            }
        }
        for (const source in config_dataanalytics) {
            if (config_dataanalytics[source]) {
                this.dataanalytics[source] = {};
                for (const plat in config_dataanalytics[source]) {
                    if (config_dataanalytics[source][plat]) {
                        this.dataanalytics[source][plat] = [];
                        for (const analy in config_dataanalytics[source][plat]) {
                            if (config_dataanalytics[source][plat][analy]) {
                                this.dataanalytics[source][plat].push({
                                    'type': analy,
                                    'name': config_dataanalytics[source][plat][analy].name
                                });
                            }
                        }
                    }
                }
            }
        }
        //setup new place holders
        const convertFields = (fields: any): Array<any> => {
            const _fields: Array<any> = [];
            for (const field in fields) {
                if (fields[field]) {
                    _fields.push(extend({ 'field': field, 'name': field }, fields[field]));
                }
            }
            return _fields
        }
        for (const dest in config_datadestinations) {
            if (config_datadestinations[dest] && !config_datadestinations[dest].hidden) {
                this.datadestinations.push(extend(
                    { 'type': dest },
                    config_datadestinations[dest],
                    { 'fields': convertFields(config_datadestinations[dest].fields) }
                ));
                this.new_dest_hosted[dest] = this.tenant.isDataDestinationHosted(dest);
                this.new_dest[dest] = extend(
                    true,
                    {},
                    this.tenant.data_destinations[dest] ?
                        this.tenant.data_destinations[dest].connection :
                        {}
                );
            }
        }
        for (const pipe in config_datapipelines) {
            if (config_datapipelines[pipe] && !config_datapipelines[pipe].hidden) {
                this.datapipelines.push(extend(
                    { 'type': pipe },
                    config_datapipelines[pipe],
                    { 'fields': convertFields(config_datapipelines[pipe].fields) }
                ));
                this.new_pipe_hosted[pipe] = this.tenant.isDataPipelineHosted(pipe);
                this.new_pipe[pipe] = extend(
                    true,
                    {},
                    this.tenant.data_pipelines[pipe] ?
                        this.tenant.data_pipelines[pipe].connection :
                        {}
                );
            }
        }
        for (const analyplat in config_dataanalyticsplatforms) {
            if (config_dataanalyticsplatforms[analyplat] && !config_dataanalyticsplatforms[analyplat].hidden) {
                this.dataanalyticsplatforms.push(extend(
                    { 'type': analyplat },
                    config_dataanalyticsplatforms[analyplat],
                    { 'fields': convertFields(config_dataanalyticsplatforms[analyplat].fields) }
                ));
                this.new_plat_hosted[analyplat] = this.tenant.isDataAnalyticsPlatformHosted(analyplat);
                this.new_plat[analyplat] = extend(
                    true,
                    {},
                    this.tenant.data_analyticsplatforms[analyplat] ?
                        this.tenant.data_analyticsplatforms[analyplat].connection :
                        {}
                );
            }
        }
        for (const pipe in config_datapipelines) {
            if (config_datapipelines[pipe] && !config_datapipelines[pipe].hidden) {
                this.new_source[pipe] = {};
                for (const source of this.datasources.all) {
                    this.new_source[pipe][source.type] = extend(
                        true,
                        { 'objects': [] },
                        this.tenant.data_pipelines[pipe] && this.tenant.data_pipelines[pipe].datasources ?
                            this.tenant.data_pipelines[pipe].datasources[source.type] :
                            {}
                    );
                }
            }
        }

        this.tenantJSON = null;
        this.cdr.detectChanges();
        this.tenantJSON = JSON.stringify(this.tenant, null, 2); //.replace(/\{/g, '&#123;').replace(/\}/g, '&#125;');
        this.cdr.detectChanges();

    }

    private getConfigFields(config_branch): Array<any> {
        const fields: Array<any> = [];

        return fields;
    }

    async openKeboolaExternalAuthWindow(source: any, tenantId: string, dataPipelineType: string, dataSourceType: string, isCreate: boolean) {
        const component = source.pipes[dataPipelineType].component;
        let authorizationId = uuidv4() + '-' + uuidv4(); // ((source.pipes[dataPipelineType].authorization || {}).oauth_api || {}).id;
        const url = `/externalauth?id=${encodeURIComponent(authorizationId)}&` +
            `name=${encodeURIComponent(component)}&` +
            `componentType=${encodeURIComponent(component)}&` +
            `tenantId=${encodeURIComponent(tenantId)}&` +
            `dataSourceType=${encodeURIComponent(dataSourceType)}&` +
            `dataPipelineType=${encodeURIComponent(dataPipelineType)}`;

        const wnd = window.open(url, component, 'height=700,width=500,scrollbars,status');

        let externalAuthToken: string;
        const alyosha_keboola_authorize_credential_id = await new Promise<string>((resolve, reject) => {
            const interval_hanlder = setInterval(() => {
                try {
                    if (wnd.closed) {
                        clearInterval(interval_hanlder);
                        const id = (<HTMLInputElement>wnd.document.getElementById('alyosha_keboola_authorize_credential_id')).value;
                        externalAuthToken = (<HTMLInputElement>wnd.document.getElementById('external_auth_token')).value;
                        resolve(id);
                    }
                } catch (err) {
                    reject(err);
                }
            }, 500);
        });
        const success = !!alyosha_keboola_authorize_credential_id;
        if (success) {
            authorizationId = alyosha_keboola_authorize_credential_id
            if (authorizationId) {
                this.new_source[dataPipelineType][dataSourceType].authorization = {
                    'oauth_api': {
                        'id': authorizationId
                    }
                }
            }
            if (isCreate) {
                this.addSource(dataPipelineType, dataSourceType);
            }
            else {
                this.updateSource(dataPipelineType, dataSourceType);
            }
        }
    }
}