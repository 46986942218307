import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { HttpBaseService } from './http-base.service';
import { AuthService } from 'app/services/auth.service';
import { DateUtil } from 'app/util/date.util';
import { AppService } from './app.service';
import {

} from 'alyosha-libs/dist/APIModels/external-auth.api-models';
import Tenant from '../models/tenant';

@Injectable()
export class ExternalAuthService extends HttpBaseService {
    constructor(private http: HttpClient, private auth: AuthService, private dateUtil: DateUtil, private appService: AppService) {
        super(http, auth);
    }

    async updateKeboolaExternalAuth(tenantId: string, dataSourceType: string, credentialId: string) {
        const tenant = await this.appService.getTenant(tenantId);
        const dataSource = tenant.data_pipelines['keboola'].datasources[dataSourceType];
        dataSource.extractors.forEach(e => {
            e.authorization = {
                'oauth_api': {
                    'id': credentialId
                }
            }
        });

        const job = await this.appService.updateTenantSource(tenant, 'keboola', dataSource);
        await this.appService.awaitJob(job.id);
    }
}