export default class Job {
    id: string;
    name: string;
    display: string;
    state_current: string;
    steps: Array<any>;
    steps_failed: Array<any>;
    status: any;
    lockeduntil: string;
    finished = false;
    success?: boolean;

    json: string;
        
    _merged = null;

    constructor(job: any) {
        this.id = job.id;
        this.merge(job);
    }

    merge(job: Job) {
        if (job.id === this.id) {
            this.name = job.name;
            this.display = job.display;
            this.state_current = job.state_current;
            this.success = job.success;
            this.steps = job.steps;
            this.steps_failed = job.steps_failed;
            this.status = job.status;
            this.lockeduntil = job.lockeduntil;
            switch ((this.state_current || '').toLowerCase()) {
                case 'success':
                case 'failed':
                    this.finished = true;
                    break;
            }
            this.json = JSON.stringify(job, null, 2);
            this._merged = new Date();
        }
    }
}