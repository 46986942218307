
//https://github.com/dchester/jsonpath/issues/89#issuecomment-371185118
import * as jp from 'jsonpath/jsonpath.min';
import * as is from 'is';

export default class Tenant {
    id: string;
    name: string;
    git_url: string;
    git_user: string;
    git_pass: string;
    created_date: string;
    created_by: string;

    jobs: any = {};
    jobKeys: Array<string>;
    data_destinations: any = {};
    data_pipelines: any = {};
    data_analyticsplatforms: any = {};

    _merged = null;

    constructor(tenant: any) {
        this.id = tenant.id;
        this.merge(tenant);
    }

    merge(tenant: Tenant) {
        if (tenant.id === this.id) {
            this.name = tenant.name;
            this.created_by = tenant.created_by;
            this.created_date = tenant.created_date;
            this.git_url = tenant.git_url;
            this.git_user = tenant.git_user;
            this.git_pass = tenant.git_pass;
            this.jobs = tenant.jobs;
            this.jobKeys = (this.jobs) ? Object.keys(this.jobs) : [];
            this.data_destinations = tenant.data_destinations;
            this.data_pipelines = tenant.data_pipelines;
            this.data_analyticsplatforms = tenant.data_analyticsplatforms;
            this._merged = new Date();
        }
    }

    hasDataDestinations(): boolean {
        if (!is.empty(this.data_destinations)) {
            for (const type in this.data_destinations) {
                if (!is.empty(this.data_destinations[type])) {
                    return true;
                }
            }
        }
        return false;
    }


    hasDataDestination(type: string): boolean {
        return !is.empty(this.data_destinations[type]);
    }

    isDataDestinationHosted(type: string): boolean {
        return this.hasDataDestination(type) && (
            !this.data_destinations[type].connection ||
            !is.string(this.data_destinations[type].connection.database) ||
            this.data_destinations[type].connection.database.toLowerCase().indexOf(this.id) > -1
        );
    }


    getDataDestinationPath(path: string): any {
        return jp.value(this.data_destinations, `$.${path}`);
    }

    setDataDestinationPath(path: string, value: any): void {
        jp.value(this.data_destinations, `$.${path}`, value);
    }

    hasDataPipelines(): boolean {
        if (!is.empty(this.data_pipelines)) {
            for (const type in this.data_pipelines) {
                if (!is.empty(this.data_pipelines[type])) {
                    return true;
                }
            }
        }
        return false;
    }

    hasDataPipeline(type: string): boolean {
        return !is.empty(this.data_pipelines[type]);
    }

    isDataPipelineHosted(type: string): boolean {
        return this.hasDataPipeline(type) && (
            !this.data_pipelines[type].connection ||
            !is.string(this.data_pipelines[type].connection.project) ||
            this.data_pipelines[type].connection.project.toLowerCase().indexOf(this.id) > -1
        );
    }

    getDataPipelinePath(path: string): any {
        return jp.value(this.data_pipelines, `$.${path}`);
    }

    setDataPipelinePath(path: string, value: any): void {
        jp.value(this.data_pipelines, `$.${path}`, value);
    }

    getDataSourcePath(pipe: string, path: string): any {
        return jp.value(this.data_pipelines, `$.${pipe}.datasources.${path}`);
    }

    setDataSourcePath(pipe: string, path: string, value: any): void {
        jp.value(this.data_pipelines, `$.${pipe}.datasources.${path}`, value);
    }

    hasDataSources(pipe?: string): boolean {
        if (!is.empty(this.data_pipelines)) {
            for (const type in this.data_pipelines) {
                if ((!pipe || type === pipe) && !is.empty(this.data_pipelines[type]) && !is.empty(this.data_pipelines[type].datasources)) {
                    for (const source in this.data_pipelines[type].datasources) {
                        if (!is.empty(this.data_pipelines[type].datasources[source])) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    hasDataSource(pipe: string, source: string): boolean {
        return this.hasDataPipeline(pipe) &&
            this.data_pipelines[pipe].datasources &&
            !is.empty(this.data_pipelines[pipe].datasources[source])
    }

    hasDataModels(dest?: string): boolean {
        if (!is.empty(this.data_destinations)) {
            for (const type in this.data_destinations) {
                if ((!dest || type === dest) && !is.empty(this.data_destinations[type]) && !is.empty(this.data_destinations[type].datamodels)) {
                    for (const model in this.data_destinations[type].datamodels) {
                        if (!is.empty(this.data_destinations[type].datamodels[model])) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    hasDataModel(dest: string, model: string): boolean {
        return this.hasDataDestination(dest) &&
            this.data_destinations[dest].datamodels &&
            this.data_destinations[dest].datamodels[model] &&
            !is.empty(this.data_destinations[dest].datamodels[model])
    }

    hasDataAnalyticsPlatforms(): boolean {
        if (!is.empty(this.data_analyticsplatforms)) {
            for (const type in this.data_analyticsplatforms) {
                if (!is.empty(this.data_analyticsplatforms[type])) {
                    return true;
                }
            }
        }
        return false;
    }

    hasDataAnalyticsPlatform(type: string): boolean {
        if (!is.empty(this.data_analyticsplatforms[type])) {
            const test = 0;
        }
        return !is.empty(this.data_analyticsplatforms[type]);
    }

    isDataAnalyticsPlatformHosted(type: string): boolean {
        return false; //this.hasDataAnalyticsPlatform(type) && !this.data_analyticsplatforms[type].connection;
    }

    getDataAnalyticsPlatformPath(path: string): any {
        return jp.value(this.data_analyticsplatforms, `$.${path}`);
    }

    setDataAnalyticsPlatformPath(path: string, value: any): void {
        jp.value(this.data_pipelines, `$.${path}`, value);
    }

    hasDataAnalytics(plat: string, dest: string, source?: string): boolean {
        if (!is.empty(this.data_analyticsplatforms) && !is.empty(this.data_destinations)) {
            if (!is.empty(this.data_analyticsplatforms[plat]) && !is.empty(this.data_destinations[dest])) {
                if (!is.empty(this.data_analyticsplatforms[plat].dataanalytics) && !is.empty(this.data_destinations[dest].dataanalytics)) {
                    for (const _source in this.data_analyticsplatforms[plat].dataanalytics) {
                        if ((!source || source === _source) && !is.empty(this.data_analyticsplatforms[plat].dataanalytics[_source])) {
                            for (const analytic in this.data_analyticsplatforms[plat].dataanalytics[_source]) {
                                if (!is.empty(this.data_analyticsplatforms[plat].dataanalytics[_source][analytic])) {
                                    return true;
                                }
                            }
                        }
                    }
                }
            }
        }
        return false;
    }

    hasDataAnalytic(dest: string, source: string, plat: string, analytic: string): boolean {
        return this.hasDataDestination(dest) &&
            this.data_destinations[dest].dataanalytics &&
            this.data_destinations[dest].dataanalytics[source] &&
            this.data_destinations[dest].dataanalytics[source][plat] &&
            this.data_destinations[dest].dataanalytics[source][plat][analytic] &&
            this.hasDataAnalyticsPlatform(plat) &&
            this.data_analyticsplatforms[plat].dataanalytics &&
            this.data_analyticsplatforms[plat].dataanalytics[source] &&
            !is.empty(this.data_analyticsplatforms[plat].dataanalytics[source][analytic])
    }
}