import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Log } from './log.model';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.scss']
})
export class LogComponent implements OnInit {
  @Input()
  log: Log;
  @Input()
  isDetailVisible: boolean;
  @Output()
  onToggleDetail: EventEmitter<Log> = new EventEmitter<Log>();

  constructor() { }

  ngOnInit() {
  }

  toggleDetail(log) {
    this.onToggleDetail.emit(log);
  }

  messageKeys(log: Log): string[] {
    return Object.keys(log.message);
  }

  eventName(log): string {
    return (log.message.event === 'step_start' || log.message.event === 'step_end') ? `${log.message.event} - ${log.message.name}` : log.message.event;
  }
}
