import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { AppService } from 'app/services/app.service';
import { OnInit } from '@angular/core/src/metadata/lifecycle_hooks';
import Tenant from 'app/models/tenant';
import { ConfirmationService } from 'primeng/primeng';
import * as is from 'is';
import * as extend from 'extend';
import Job from 'app/models/job';
import { AuthService } from '../../services/auth.service';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-tenants',
    templateUrl: './tenants.component.html',
    styleUrls: ['./tenants.component.scss']
})
export class TenantsComponent implements OnInit {
    blockedTable = false;
    errorMessage: string | null = null;
    addTenantPopup = false;

    tenants: Array<Tenant>;
    tenant: Tenant;
    job: Job;
    jobs: Array<Job> = [];
    showJob = false;

    new_tenant_name: string;
    new_tenant_git_url: string;
    new_tenant_git_user: string;
    new_tenant_git_pass: string;
    
    constructor(private cdr: ChangeDetectorRef, private appService: AppService, private confirmationService: ConfirmationService, private authService: AuthService) { }

    async ngOnInit() {
        this.blockedTable = true;
        this.cdr.detectChanges();
        this.tenants = await this.appService.getTenants();
        this.blockedTable = false;
        this.cdr.detectChanges();
    }

    addTenantPopupOpen() {
        this.addTenantPopup = true;
        this.cdr.detectChanges();
    }

    addTenantPopupClose() {
        this.addTenantPopup = false;
        this.cdr.detectChanges();
    }

    errorMessagePopupClose() {
        this.errorMessage = null;
        this.cdr.detectChanges();
    }

    async refresh() {
        this.blockedTable = true;
        this.cdr.detectChanges();
        this.tenants = await this.appService.getTenants();
        this.blockedTable = false;
        this.cdr.detectChanges();
    }

    async addTenant() {
        this.blockedTable = true;
        this.addTenantPopupClose();
        try {
            this.authService.getUserEmail().then(async(email: string)=>{
                const tenant = await this.appService.addTenant(new Tenant({
                    'name': this.new_tenant_name,
                    'git_url': this.new_tenant_git_url,
                    'git_user': this.new_tenant_git_user,
                    'git_pass': this.new_tenant_git_pass,
                    'created_date': new Date(),
                    'created_by': email                    
                }));
                this.tenants = [...this.tenants, tenant];
            }).then((addedTenant)=>{
                this.blockedTable = false;
                this.cdr.markForCheck();
                this.cdr.detectChanges();
            })
        }
        catch (ex) {
            console.error(ex);
        }
    }

    async removeTenant(id: string) {
        this.confirmationService.confirm({
            key: 'tenants_container_confirm',
            'message': 'Are you sure that you want to remove this tenant?',
            'header': 'Remove Tenant',
            'icon': 'fa-times-circle',
            'accept': async () => {
                this.blockedTable = true;
                this.cdr.detectChanges();
                try {
                    const job: Job = await this.appService.removeTenant(id);
                    //show the job tree
                    this.getAndShowJob(job.id, () => {
                        this.tenants = this.tenants.filter(t => { return t.id !== id; });
                        this.cdr.detectChanges();
                    });
                }
                catch (ex) {
                    //ignore
                }
                this.blockedTable = false;
                this.cdr.detectChanges();
            }
        });
    }

    async getAndShowJob(id, callback?: () => void) {
        this.showJob = true;
        this.job = null;
        this.cdr.detectChanges();
        const job = await this.appService.getJob(id);
        this.job = job;
        if (!this.jobs.filter(j => j.id === job.id).length) {
            this.jobs.push(job);
        }
        this.cdr.detectChanges();
        await this.appService.awaitJob(id, (_job: Job) => {
            job.merge(_job);
            this.cdr.detectChanges();
        });
        //callback
        if (callback) {
            setTimeout(callback, 10);
        }
    };
}
