import { Component, Input, ChangeDetectorRef, ChangeDetectionStrategy, EventEmitter, Output, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppService } from 'app/services/app.service';
import { UserService } from 'app/services/user.service';
import { ConfirmationService, TreeNode, TabView } from 'primeng/primeng';
import Tenant from 'app/models/tenant';
import * as is from 'is';
import * as extend from 'extend';
import Job from 'app/models/job';
import * as moment from 'moment';
import { IEventLog } from 'alyosha-libs/dist/Cloud/CloudLog';
import { Util } from '../../../services/util.service';
import { UserModel } from '../../../models/user';
import { DataTableModule } from 'primeng/primeng';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UsersComponent implements OnInit, OnDestroy {

    @Input() tenantId: string;

    newUserEmail: string;
    users: UserModel[];
    allRoleIds: string[];
    allRoles: any[];
    loading = true;

    constructor(private cdr: ChangeDetectorRef,
        private appService: AppService,
        private userService: UserService,
        private confirmationService: ConfirmationService,
        private util: Util
    ) {

    }

    ngOnInit() {
        this.loadUsers();
        this.loadAllRoles();
    }

    loadUsers() {
        this.loading = true;
        return new Promise((resolve, reject) => {
            this.userService.getUsersForTenant(this.tenantId).then(u => {
                this.users = u;
                this.loading = false;
                this.cdr.markForCheck();
                this.cdr.detectChanges();
                resolve();
            });
        })

    }

    loadAllRoles() {
        this.userService.getAllAvailableRoles().then((roles) => {
            this.allRoles = roles;
        }).catch((err) => {
            console.error(err);
        });
    }

    isCurrentUserTenantAdmin(): boolean {
        return this.userService.isCurrentUserTenantAdmin();
    }

    roleCheckBoxChange(checked: boolean, roleName: string, user: UserModel) {
        if (this.isCurrentUserTenantAdmin() === true) {
            //find role id
            const role = this.allRoles.find(x => x.name === roleName);
            const id = role.id;
            this.loading = true;
            //do it, roles are checked in api
            this.userService.addUserToRole(user.email, id, roleName, checked).then(() => {
                this.cdr.markForCheck();
                this.cdr.detectChanges();
                this.loadUsers().then(() => {
                    setTimeout(() => {
                        this.users = [...this.users];
                        this.cdr.markForCheck();
                        this.cdr.detectChanges();
                    });
                });
            });
        }
    }

    hasRole(user: UserModel, roleName: string): boolean {
        return user && roleName && user.roles && user.roles.indexOf(roleName) > -1;
    }

    ngOnDestroy() {

    }


    addUser(e) {

        const newUser = <UserModel>{
            email: this.newUserEmail,
            id: null,
            tenant_id: this.tenantId
        }
        this.loading = true;
        this.cdr.markForCheck();
        this.userService.addUser(newUser).then((addedUser) => {
            this.loading = false;
            this.users = [...this.users, addedUser];
            this.cdr.markForCheck();
            this.cdr.detectChanges();
        });
    }

    removeUser(user: UserModel) {
        this.loading = true;
        this.userService.removeUser(user).then(() => {
            this.loadUsers();
        });
    }

    validateEmail(): boolean {
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.newUserEmail)
    }


}