import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { RequestOptions } from 'http';
import { ExternalAuthService } from '../../services/external-auth.service';
import { AppService } from '../../services/app.service'

@Component({
    selector: 'app-external-auth',
    templateUrl: './external-auth.component.html',
    styleUrls: ['./external-auth.component.scss']
})
export class ExternalAuthComponent implements OnInit {
    externalAuthToken: string;
    componentType: string;
    name: string;
    id: string;
    returnUrl: string;
    authorizedFor: string;
    tenantId: string;
    dataPipelineType: string;
    dataSourceType: string;
    isCallback: string;
    alyosha_keboola_authorize_credential_id: string;
    updateBody: any;

    constructor(private route: ActivatedRoute, private externalAuthService: ExternalAuthService, private appService: AppService) { }

    async ngOnInit() {
        this.route.queryParams
            .subscribe(async params => {
                this.componentType = params.componentType;
                this.id = params.id;
                this.name = params.name;
                this.isCallback = params.isCallback;
                this.tenantId = params.tenantId
                this.dataSourceType = params.dataSourceType;
                this.dataPipelineType = params.dataPipelineType;
                this.authorizedFor = `Alyosha - ${this.name}`;


                if (!this.isCallback) {
                    await this.submit();
                }
                else {

                    await this.update(this.id, params.token);
                }
            });
    }

    async submit() {
        let job = await this.appService.generateExternalAuthToken(this.tenantId, this.dataPipelineType, this.dataSourceType);
        job = await this.appService.awaitJob(job.id);
        this.externalAuthToken = JSON.parse(job.steps[0].msg).externalAuthToken;
        this.returnUrl = `${window.location.protocol}//${window.location.host}/externalauth?isCallback=1&` +
            `token=${this.externalAuthToken}&id=${this.id}&componentType=${this.componentType}&` +
            `tenantId=${this.tenantId}&dataSourceType=${this.dataSourceType}&dataPipelineType=${this.dataPipelineType}`;

        // automatically submit on navigate
        setTimeout(() => {
            window.document.getElementsByTagName('button')[0].click();
        });
    }

    async update(credentialId: string, externalAuthToken: string) {
        this.alyosha_keboola_authorize_credential_id = credentialId;
        this.externalAuthToken = externalAuthToken;
        // close window
        window.close();
    }

}
