import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { IEventLog } from 'alyosha-libs/dist/Cloud/CloudLog';
import { Log } from './log/log.model';
import * as moment from 'moment';

@Component({
  selector: 'app-log-list',
  templateUrl: './log-list.component.html',
  styleUrls: ['./log-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogListComponent implements OnInit {
  @Input('logs')
  set logs(value: IEventLog[]) {
    value = value.sort((a, b) => {
      const aPrimary = (a || { timestamp: -1 }).timestamp;
      const bPrimary = (b || { timestamp: -1 }).timestamp;
      const primarySort = a.timestamp < b.timestamp ? 1 : a.timestamp > b.timestamp ? -1 : 0;
      if (primarySort !== 0) {
        return primarySort;
      }

      const aSecondary = (a.message || { event: 'zzzzz' }).event;
      const bSecondary = (b.message || { event: 'zzzzz' }).event;
      return aSecondary < bSecondary ? -1 : aSecondary > bSecondary ? 1 : 0;
    });

    this._logs = value.map((log: IEventLog) => {
      let l = this.eventLogs.get(log);
      if (!l) {
        l = <Log>{
          message: log.message,
          timestamp: log.timestamp,
          date: new Date(log.timestamp)
        };
        this.eventLogs.set(log, l);
      }

      l.display = moment(log.timestamp).fromNow();
      return l;
    });
  }
  _logs: Log[];

  eventLogs: Map<IEventLog, Log>;
  openLogs: Map<Log, boolean>;

  constructor(private cdr: ChangeDetectorRef) {
    this.openLogs = new Map<Log, boolean>();
    this.eventLogs = new Map<IEventLog, Log>();
  }

  ngOnInit() {
  }

  toggleDetail(log: Log) {
    this.openLogs.set(log, !this.isDetailVisible(log));
    this.cdr.markForCheck();
  }

  isDetailVisible(log: Log) {
    return this.openLogs.has(log) && this.openLogs.get(log);
  }

}
