import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';

import { TabMenuModule, MenubarModule, MenuItem } from 'primeng/primeng';
import { OnInit } from '@angular/core/src/metadata/lifecycle_hooks';
import { Router } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    profile: any;
    navItems: MenuItem[];
    activeNavItem: MenuItem;
    profileItems: MenuItem[];
    showTabMenu: boolean;

    constructor(public auth: AuthService, private router: Router) { }

    ngOnInit(): void {
        // get profile
        this.profile = this.auth.userProfile;
        if (!this.profile) {
            // need to keep checking for a valid profile till it arrives
            const interval = setInterval(() => {
                this.profile = this.auth.userProfile;
                if (this.profile) {
                    // set profile menu
                    this.profileItems = [{
                        label: this.profile.name,
                        style: { 'background-image': 'url(' + this.profile.picture + ')' },
                        items: [
                            { label: 'Profile', routerLink: 'profile' },
                            { label: 'Account', routerLink: 'account' },
                            { label: 'Logout', routerLink: 'logout' }
                        ]
                    }];
                    // dont check for profile again...
                    clearInterval(interval);
                }
            }, 100);
        }
        // setup nav items
        this.navItems = [
            { label: 'Tenants', routerLink: 'tenants' },
            //{ label: 'Jobs', routerLink: 'jobs' }
        ];

        this.showTabMenu = window.location.href.indexOf('/externalauth') === -1;
    }
}
