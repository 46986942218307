import { IEnvironment } from './environment.interface';

export const commonEnvironment: IEnvironment = <IEnvironment>{
    JWKS_URI: 'https://login.decisivedata.io/.well-known/jwks.json',
    TOKEN_ISSUER: 'https://login.decisivedata.io/',
    AUDIENCE: 'Alyosha-API',
    CLIENT_ID: 'lSzV-UA-ENKB1duykBQMr_21x8xXz_mr',
    DOMAIN: 'login.decisivedata.io',
    AUTH0_AUTHS_ENDPOINT: 'https://decisivedata.us8.webtask.io/adf6e2f2b84784b57522e3b19dfc9201/api',
    API_VERSION: 'v1'
};
