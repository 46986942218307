import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './services/auth.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(public auth: AuthService, public router: Router) { }

  canActivate(): boolean | Promise<boolean> {
    if (!this.auth.isAuthenticated()) {
      // head back to login page
      this.router.navigate(['login']);
      return false;
    }
    else if (!this.auth.userProfile) {
      // make sure the profile is gotten!
      return (new Promise((resolve, reject) => {
        this.auth.getProfile((err, profile) => {
          if (err) {
            this.router.navigate(['login']);
            reject(err);
          }
          resolve(true);
        });
      }));
    }
    // everything is in its place, head on through!
    return true;
  }
}
