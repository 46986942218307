import { Component } from '@angular/core';
import { AppService } from '../../services/app.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
    selector: 'app-callback-oauth',
    templateUrl: './callbackoauth.component.html',
    styleUrls: ['./callbackoauth.component.scss']
})
export class CallbackOAuthComponent {
    constructor(private appService: AppService, private router: Router, private activatedRoute: ActivatedRoute) {
        (new Promise((resolve, reject) => {
            this.activatedRoute.queryParams.subscribe((params: Params) => {
                resolve(params);
            })
        })).then((params) => {
            const [tenant_id, data_type, obj_type, field] = (params['state'] || '').split('|');
            //just close if there is no tenant
            if (!tenant_id) {
                window.close();
                return;
            }
            //return early if there is no code
            else if (!field || !params['code']) {
                window.opener[`__callbackOauth_${tenant_id}`]();
                window.close();
                return;
            }
            //generate the token
            const obj: any = { 'type': obj_type, 'connection': {  } };
            obj.connection[field] = params['code'];
            obj.connection['redirect_uri'] = `${location.protocol}//${location.host}/callback-oauth`;
            //create the proper obj
            switch (data_type) {
                case 'destination':
                case 'datadestination':
                case 'dest':
                    if (window.opener && window.opener[`__callbackOauth_${tenant_id}`]) {
                        window.opener[`__callbackOauth_${tenant_id}`](obj);
                        break;
                    }
                    else {
                        return appService.getTenant(tenant_id).then(tenant => {
                            return appService.setTenantDestination(tenant, obj).then((job) => {
                                return appService.awaitJob(job.id);
                            });
                        });
                    }
                case 'pipeline':
                case 'datapipeline':
                case 'pipe':
                    if (window.opener && window.opener[`__callbackOauth_${tenant_id}`]) {
                        window.opener[`__callbackOauth_${tenant_id}`](obj);
                        break;
                    }
                    else {
                        return appService.getTenant(tenant_id).then(tenant => {
                            return appService.setTenantPipeline(tenant, obj).then((job) => {
                                return appService.awaitJob(job.id);
                            });
                        });
                    }
                case 'analyticsplatform':
                case 'dataanalyticsplatform':
                case 'analyplat':
                case 'plat':
                    if (window.opener && window.opener[`__callbackOauth_${tenant_id}`]) {
                        window.opener[`__callbackOauth_${tenant_id}`](obj);
                        break;
                    }
                    else {
                        return appService.getTenant(tenant_id).then(tenant => {
                            return appService.setTenantAnalyticsPlatform(tenant, obj).then((job) => {
                                return appService.awaitJob(job.id);
                            });
                        });
                    }
                default:
                    throw new Error('Unsupported Object Type');
            }
        }).catch(ex => {
            alert(`There was an error, please try again later!\n\n\n\n${ex && ex.message ? ex.message : ex}`);
        }).then(() => {
            //this is an oauth response, likely in a new window, just close it
            if (window.opener) {
                window.close();
            }
            else {
                this.router.navigate(['']);
            }
        });
    }
}