import { Component, OnInit } from '@angular/core';
import Job from 'app/models/job';
import { AppService } from '../../services/app.service';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.scss']
})
export class JobsComponent implements OnInit {
  jobs: Array<Job>;

  constructor(private appService: AppService) { }

  ngOnInit() {
    this.appService.getJobs([]).then((data) => {
      this.jobs = data;
    }).catch((err) => console.log(err));
  }
}
