import { Component, Input, ChangeDetectorRef, ChangeDetectionStrategy, OnChanges, SimpleChanges, SimpleChange, Output, EventEmitter } from '@angular/core';
import { AppService } from 'app/services/app.service';
import { ConfirmationService, TreeNode, MenuItem } from 'primeng/primeng';
import Tenant from 'app/models/tenant';
import * as is from 'is';
import * as extend from 'extend';
import * as moment from 'moment';
import { OnInit, OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import Job from 'app/models/job';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-jobtree',
    templateUrl: './jobtree.component.html',
    styleUrls: ['./jobtree.component.scss']
})
export class JobtreeComponent implements OnInit, OnChanges, OnDestroy {
    @Input() tenant: Tenant;
    @Input() jobs: Array<Job>;
    @Input() selectedJob: Job;
    //@Output() removeJob: EventEmitter;

    job: Job;

    menuItems: MenuItem[] = [];
    selectedMenuItem: MenuItem;
    root_node: TreeNode[] | null = null;
    updateInterval: any;

    showJobJSON: boolean;
    showStepJSON: boolean;
    showMsgJSON: boolean;
    jobJSON: string;
    stepJSON: string;
    msgJSON: string;

    constructor(private cdr: ChangeDetectorRef, private appService: AppService, private confirmationService: ConfirmationService) { }

    ngOnInit() {
        let merged = null;
        this.updateInterval = setInterval(() => {
            if (this.job && this.job._merged !== merged) {
                merged = this.job._merged;
                this.setJob(this.job);
            }
        }, 250);
    }

    ngOnDestroy() {
        clearInterval(this.updateInterval);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.jobs) {
            const numOfTabs = 3;
            const _menuItems: Array<MenuItem> = changes.jobs.currentValue.map((j) => {
                return {
                    'label': (j.display || (j.steps && j.steps.length ? (j.steps[0].display || j.steps[0].name) : j.name) || j.name),
                    'id': j.id,
                    'command': (e) => { 
                        this.selectedMenuItem = e.item;
                        this.setJob(this.jobs.filter(j2 => j2.id === e.item.id)[0]); 
                    }
                };
            });
            //limit tabs to 3 (but always include the selectedJob)
            this.menuItems = [];
            let foundSelected = false;
            for (const item of _menuItems.reverse()) {
                if (!foundSelected && this.menuItems.length === (numOfTabs - 1) && item.id !== this.selectedJob.id) {
                    //skip id
                }
                else if (this.menuItems.length < numOfTabs) {
                    this.menuItems.push(item);
                    if (item.id === this.selectedJob.id) {
                        foundSelected = true;
                    }
                }
                else if (this.menuItems.length >= numOfTabs) {
                    break;
                }
            }
            this.menuItems.reverse();
        }
        if (changes.selectedJob) {
            const item = this.menuItems.filter(i => i.id === changes.selectedJob.currentValue.id);
            if (item.length) {
                this.selectedMenuItem = item[0];
                this.setJob(this.jobs.filter(j => j.id === this.selectedMenuItem.id)[0]);
            }
        }
    }

    private sanitizeJSON(json: string) {
        return json.replace(/\\r(\\n)?/g, ' '); //.replace(/\{/g, '&#123;').replace(/\}/g, '&#125;');
    }

    showJobJSONPopup() {
        this.jobJSON = this.sanitizeJSON(this.job.json);
        this.showJobJSON = true;
        this.cdr.detectChanges();
    }

    showStepJSONPopup(step: any) {
        this.stepJSON = this.sanitizeJSON(JSON.stringify(extend({}, step, { children: '...' }), null, 2));
        this.showStepJSON = true;
        this.cdr.detectChanges();
    }

    showMsgJSONPopup(json: string) {
        this.msgJSON = this.sanitizeJSON(json);
        this.showMsgJSON = true;
        this.cdr.detectChanges();
    }

    isJSON(json: string) {
        try {
            if (json.indexOf('{') === 0 || json.indexOf('[') === 0) {
                JSON.parse(json);
                return true;
            }
        }
        catch (ex) {
            //not json
        }
        return false;
    }

    setJob(job?: Job | null) {
        if (!job) {
            this.root_node = [];
            this.cdr.detectChanges();
            return;
        }
        const data: TreeNode[] = [];
        const recurse = (step: any, parent: TreeNode) => {
            let icon = '';
            let header = 'Pending';
            let message = '';
            let percent: number | null = null;
            let sClass = 'step_pending';
            switch (step.state_current) {
                case 'success': 
                    icon = 'fa-check-circle';
                    header = 'Message';
                    if (is.string(step.msg) || is.number(step.msg)) {
                        message = step.msg;
                    }
                    else if (step.msg) {
                        message = JSON.stringify(step.msg, null, 2);
                    }
                    sClass = 'step_success';
                    break;
                case 'failed': 
                    icon = 'fa-times-circle';
                    header = 'Error';
                    if (is.string(step.err) || is.number(step.err)) {
                        message = step.err;
                    }
                    else if (step.err && step.err.message) {
                        message = JSON.stringify(step.err.message, null, 2);
                    }
                    else if (step.err) {
                        message = JSON.stringify(step.err, null, 2)
                    }
                    else {
                        message = 'No error information supplied =(';
                    }
                    sClass = 'step_failed';
                    break;
                case 'running': 
                    icon = 'fa-play-circle';
                    header = 'Progress';
                    if (is.string(step.msg) || is.number(step.msg)) {
                        message = step.msg;
                    }
                    else if (step.msg) {
                        message = JSON.stringify(step.msg, null, 2);
                    }
                    if (step.percent_complete) {
                        if (!message) {
                            message = '&nbsp;';
                        }
                        percent = Math.round(step.percent_complete);
                    }
                    sClass = 'step_running';
                    break;
                case 'canceled': 
                    icon = 'fa-times-circle';
                    header = 'Canceled';
                    sClass = 'step_canceled';
                    break;
            }
            //calc step duration
            let duration: any = null;
            const start = step.started ? moment.utc(step.started).local().calendar() : null;
            if (step.finished) {
                duration = moment.utc(step.finished).diff(moment.utc(step.started));
                if (duration > 1000) {
                    duration = `${Math.ceil(duration / 1000)}s`;
                }
                else {
                    duration = `${duration}ms`;
                }
            }
            else if (step.started) {
                duration = moment.utc(step.started).toNow();
            }
            //create the tree node!
            const node: TreeNode = {
                label: step.display || step.name,
                icon: {
                    step: step,
                    name: icon,
                    time: start ? `${start} (${duration})` : 'Not Started',
                    header: header,
                    message: message,
                    percent_complete: percent
                },
                styleClass: sClass,
                expanded: true,
                selectable: false,
            };
            parent.children.push(node);
            //recurse
            if (step.children) {
                node.children = [];
                step.children.forEach(child => recurse(child, node));
            }
        }
        this.job = job;
        if (is.array(job.steps)) {
            for (const step of job.steps) {
                recurse(step, { label: '', children: data });
            }
        }
        this.root_node = [];
        this.cdr.detectChanges();
        this.root_node = data;
        this.cdr.detectChanges();
    }
}