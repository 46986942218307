import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as auth0 from 'auth0-js';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class AuthService {

    private emailField = 'https://decisivedata.io/email';
    private sessionCheckerIntervalHandle: number;

    groups: string[];
    permissions: string[];
    roles: string[];
    rolesField = 'https://decisivedata.io/roles';
    userProfile: any;

    options: any = {
        clientID: environment.CLIENT_ID,
        domain: environment.DOMAIN,
        responseType: 'token id_token',
        audience: environment.AUDIENCE,
        redirectUri: environment.CALLBACK_URI,
        scope: 'openid profile groups permissions roles'
    };

    auth0 = new auth0.WebAuth(this.options);

    constructor(public router: Router, private http: HttpClient) {

    }

    public login(): void {
        this.auth0.authorize();
    }

    public isCurrentUserTenantAdmin(): boolean {
        return this.isAuthenticated() &&
            this.userProfile &&
            Array.isArray(this.userProfile[this.rolesField]) &&
            this.userProfile[this.rolesField].indexOf('Tenant Admin') > -1;

    }

    //check every 9 minutes
    public setSessionChecker() {

        if (this.sessionCheckerIntervalHandle) {
            window.clearInterval(this.sessionCheckerIntervalHandle);
        }

        this.sessionCheckerIntervalHandle =
            window.setInterval(() => {
                if (this.isTokenAboutToExpire() && this.isAuthenticated()) {
                    this.checkSession_impl(this.setSessionChecker.bind(this));
                }
            }, 8.5 * 60 * 1000); // check every 8.5 minutes
    }

    // get a new token if the token will expire soon, improve user experience
    public checkSession(): void {
        if (this.isAuthenticated() && this.isTokenAboutToExpire()) {
            this.checkSession_impl(this.setSessionChecker.bind(this));
        } else if (!this.sessionCheckerIntervalHandle && this.isAuthenticated()) {
            this.setSessionChecker();
        }
    }

    private checkSession_impl(cb) {
        this.auth0.checkSession({}, (err, authResult) => {
            this.handleParseHash(err, authResult, cb);
        });
    }

    public isTokenAboutToExpire() {
        const session = this.getSession();
        if (!session.expires_at || isNaN(parseFloat(session.expires_at))) {
            return false;
        }
        const expireMinutes = (parseFloat(session.expires_at) / 1000) / 60;
        const nowMinutes = (new Date().getTime() / 1000) / 60;
        if (expireMinutes - nowMinutes < 10) {
            return true;
        } else {
            return false;
        }
    }

    public handleAuthentication(cb): void {
        this.auth0.parseHash((err, authResult) => {
            this.handleParseHash(err, authResult, cb);
        });
    }

    private handleParseHash(err, authResult, cb) {
        if (authResult && authResult.accessToken && authResult.accessToken.length > 40 && authResult.idToken) {
            this.setSession(authResult);
            if (typeof cb === 'function') {
                cb();
            }
            this.router.navigate(['/home']);
        } else if (err) {
            this.router.navigate(['/home']);
            console.log(err);
            alert(`Error: ${err.error}. Check the console for further details.`);
        }
    }

    private setSession(authResult): void {
        // Set the time that the access token will expire at
        const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('id_token', authResult.idToken);
        localStorage.setItem('expires_at', expiresAt);
    }

    public getSession() {
        return {
            access_token: localStorage.getItem('access_token'),
            id_token: localStorage.getItem('id_token'),
            expires_at: localStorage.getItem('expires_at')
        }
    }

    public logout(): void {
        // Remove tokens and expiry time from localStorage
        localStorage.removeItem('access_token');
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        // Go back to the home route
        this.router.navigate(['/']);
    }

    public isAuthenticated(): boolean {
        // Check whether the current time is past the
        // access token's expiry time
        const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
        return new Date().getTime() < expiresAt;
    }


    //...
    public getProfile(cb): void {
        const accessToken = localStorage.getItem('access_token');
        if (accessToken) {
            const self = this;
            this.auth0.client.userInfo(accessToken, (err, profile) => {
                if (profile) {
                    self.userProfile = profile;
                }
                cb(err, profile);
            });
        }
    }

    public getUserEmail(): Promise<string> {
        return new Promise((resolve, reject) => {
            if (this.userProfile) {
                resolve(this.userProfile[this.emailField]);
            } else {
                this.getProfile(() => {
                    if (this.userProfile) {
                        resolve(this.userProfile[this.emailField]);
                    } else {
                        reject();
                    }
                })
            }
        })

    }
}
